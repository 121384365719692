<template>
  <div class="w-full px-3">
    <CustomLabel
      >{{ label }}<template v-slot:normal>{{ label2 }}</template></CustomLabel
    >
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="
        block
        w-full
        px-4
        py-3
        leading-tight
        text-gray-700
        bg-gray-200
        rounded
        appearance-none
        focus:outline-none focus:bg-white
      "
      :class="error ? 'border-2 border-red-500  focus:border-red-500' : normalBorder"
      type="text"
      :placeholder="placeholder"
      :maxlength="maxLength"
      @keypress="force ? fc[force](event) : null"
    />
  </div>
</template>
<script>
import CustomLabel from './CustomLabel.vue';
export default {
  components: { CustomLabel },
  props: {
    label: {
      type: String,
      required: true,
    },
    label2: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    maxLength: {
      default: undefined,
    },
    force: {
      type: String,
      default: undefined,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const fc = [];
    fc['isNumber'] = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    fc['noComparisonOperator'] = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 60 || charCode == 62) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    return {
      errorBorder: 'border-2 border-red-500  focus:border-red-500',
      normalBorder: 'border border-gray-200 focus:border-gray-500',
      fc,
    };
  },
};
</script>
