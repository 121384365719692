<template>
  <div class="w-full px-3">
    <CustomLabel>{{ label }}</CustomLabel>
    <select
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      class="
        block
        w-full
        px-3
        py-4
        text-base
        leading-tight
        text-black
        bg-gray-200
        border-2
        appearance-none
        rounded-xl
        focus:outline-none
      "
      :class="error ? 'border-2 focus:border-red-500 border-red-500' : null"
      :disabled="disabled"
    >
      <option v-if="showPlaceholder" value="" hidden>Selektiere {{ label }} ...</option>
      <option
        :selected="option.value == defaultValue ? 'selected' : null"
        class="bg-white"
        v-for="option in options"
        :key="option.id"
        :value="option.id ? JSON.stringify({ id: option.id, name: option.name }) : option.value ?? option"
      >
        {{ option.name ? option.name : option }}
      </option>
    </select>
  </div>
</template>
<script>
import CustomLabel from './CustomLabel.vue';
export default {
  components: { CustomLabel },
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String,
      deafult: '',
    },
    options: {
      required: true,
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
