<template>
  <Card>
    <!-- <div>Importiere neue Leads für dein Postleitzahlgebiet</div> -->

    <div class="flex justify-between">
      <div class="flex">
        <ToggleButton
          label="Mitarbeiter"
          :current-state="filterEmployees"
          @change-state="filterEmployees = !filterEmployees"
        ></ToggleButton>
      </div>
      <div class="flex">
        <ToggleButton label="Umsatz" :current-state="filterRevenue" @change-state="filterRevenue = !filterRevenue" />
      </div>
      <div class="flex">
        <Dropdown
          v-model="type"
          label="Import"
          :options="[
            { name: 'Einzel Import', value: 'single' },
            { name: 'Massen Import', value: 'mass' },
          ]"
          :showPlaceholder="false"
        />
      </div>
      <div class="flex">
        <Dropdown
          v-model="form.orderBy"
          label="Sortierung"
          :options="[
            { name: 'Umsatz', value: 'revenue' },
            { name: 'Mitarbeiter', value: 'employees' },
          ]"
          :showPlaceholder="false"
        />
      </div>
    </div>

    <div v-show="filterEmployees" class="flex">
      <InputText v-model="form.employees.from" force="isNumber" label="Mitarbeiter Von" placeholder="50" />
      <InputText v-model="form.employees.to" label="Mitarbeiter Bis" placeholder="250" force="isNumber"> </InputText>
    </div>
    <div v-show="filterRevenue" class="flex">
      <InputText v-model="form.revenue.from" label="Umsatz Von" placeholder="10000000" force="isNumber" />
      <InputText v-model="form.revenue.to" label="Umsatz Bis" placeholder="2500000" force="isNumber"> </InputText>
    </div>
    <div class="flex items-end">
      <Dropdown
        v-model="team"
        label="Team"
        :showPlaceholder="form.type === 'mass' ? true : true"
        :options="
          form.type === 'mass'
            ? Object.values(teams).filter(
                (x) => x.name.match(/(\d+)/)[0].substring(0, 2) === user.branch.match(/(\d+)/)[0].substring(0, 2),
              )
            : teams
        "
      />
      <Dropdown v-model="salesRegion" label="V-Gebiet" :options="selectedSalesRegions" :disabled="team === ''" />
      <div class="mr-3">
        <ButtonOms color="green" class="h-14 w-14" @click="salesRegion ? form.salesRegions.push(salesRegion) : false">
          +
        </ButtonOms>
      </div>
    </div>
    <div class="flex items-end">
      <InputText
        v-model="plz.from"
        label="PLZ Von"
        placeholder="10000"
        :max-length="5"
        :error="formError.plz.from"
        @keyup.enter="newPlz"
        force="isNumber"
      />
      <InputText
        v-model="plz.to"
        label="PLZ Bis"
        placeholder="10999"
        :max-length="5"
        :error="formError.plz.to"
        @keyup.enter="newPlz"
        force="isNumber"
      >
      </InputText>
      <div class="mr-3">
        <ButtonOms color="green" class="h-14 w-14" @click="newPlz"> + </ButtonOms>
      </div>
    </div>
    <InputText
      v-if="form.type === 'single'"
      v-model="form.search"
      label="Unternehmensname"
      label2="Fängt an mit ..."
      placeholder="OMS Vertriebsgesellschaft mbH"
      v-on:keyup.enter="startFetching"
    >
    </InputText>
    <VueSlider
      v-if="form.type === 'mass'"
      class="mx-3 mt-12"
      v-model="form.limit"
      :min="1"
      :max="500"
      :drag-on-click="true"
      :tooltip="'always'"
      dot-size="25"
      style="height: 10px"
    />
    <div class="mb-4 text-2xl font-bold">
      <div v-if="user.ok === false">Es gibt Problemen bei die Verbindung zum Server.</div>
      <div v-else-if="form.type === 'mass' && !user.mass && user.ok === true">Du hast nur Berechtigung für Einzel Import.</div>
    </div>

    <div class="flex items-end justify-between">
      {{ errorMessage }}
      <div>
        <ul>
          <li v-for="(plzArea, index) in form.plz" :key="index">
            {{ plzArea.from }} bis
            {{ plzArea.to }}
            <ButtonOms color="red" size="small" @click="form.plz.splice(index, 1)"> Entfernen </ButtonOms>
          </li>
        </ul>
        <ul>
          <li v-for="(salesRegion, index) in form.salesRegions" :key="index" class="text-left">
            {{ salesRegion }}
            <ButtonOms color="red" size="small" @click="form.salesRegions.splice(index, 1)"> Entfernen </ButtonOms>
          </li>
        </ul>
      </div>

      <div v-if="user.ok === true && !(form.type === 'mass' && !user.mass)">
        <ButtonOms color="blue" class="mx-4" @click="startFetching">{{ abgleichText }}</ButtonOms>
      </div>
    </div>
  </Card>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import InputText from './html/InputText';
import ToggleButton from './html/ToggleButton';
import { reactive, ref } from '@vue/reactivity';
import Card from './html/Card.vue';
import ButtonOms from './html/ButtonOms.vue';
import useCadis from '@/composables/useCadis';
import { computed, watch } from '@vue/runtime-core';
import Dropdown from './html/Dropdown.vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    VueSlider,
    InputText,
    ToggleButton,
    Card,
    ButtonOms,
    Dropdown,
  },

  setup() {
    const route = useRoute();
    const { fetchComparison, user, sentForm, toImport, teams, salesRegions, onlyDuplicates } = useCadis();
    const form = reactive({
      orderBy: 'revenue',
      employees: { from: '', to: '' },
      revenue: { from: '', to: '' },
      plz: [],
      salesRegions: [],
      search: '',
      type: '',
      limit: 50,
    });
    const type = ref(route.query.type ?? 'single');
    form.type = type.value;
    const filterEmployees = ref(false);
    const filterRevenue = ref(false);
    const plz = reactive({ from: '', to: '' });
    const selectedSalesRegions = ref();
    const salesRegion = ref('');
    const team = ref('');
    const formError = reactive({ plz: { from: false, to: false } });
    const repeat = ref(false);
    const errorMessage = ref(
      computed(() => {
        if (!form.plz.length && !form.salesRegions.length && form.type === 'mass') {
          return 'Füge mindestens ein Gebiet hinzu.';
        } else if (!form.plz.length && !form.search.length && !form.salesRegions.length && form.type === 'single') {
          return 'Füge Unternehmensname oder Gebiet hinzu.';
        } else return '';
      }),
    );

    const determineRegions = () => {
      if (salesRegions.value !== undefined) {
        if (!team.value || JSON.parse(team.value).name.startsWith('PDE7000')) {
          selectedSalesRegions.value = salesRegions.value.map((x) => x.sales_region);
        } else if (JSON.parse(team.value).name.substring(0, 7).endsWith('01')) {
          selectedSalesRegions.value = Object.values(salesRegions.value)
            .filter((x) => x.operating_unit == JSON.parse(team.value).name.substring(0, 7))
            .map((x) => x.sales_region);
        } else {
          selectedSalesRegions.value = Object.values(salesRegions.value)
            .filter((x) => x.branch == JSON.parse(team.value).name.substring(0, 7))
            .map((x) => x.sales_region);
        }
      }
    };

    watch(team, () => {
      determineRegions();
      salesRegion.value = '';
    });
    watch(type, () => {
      form.type = type.value;
      team.value = '';
      salesRegion.value = '';
    });

    const newPlz = () => {
      formError.plz.from = plz.from.length !== 5 ? true : false;
      formError.plz.to = plz.to.length !== 5 ? true : false;
      if (formError.plz.from || formError.plz.to) return;
      let plzArea = {
        from: plz.from,
        to: plz.to,
      };
      form.plz.push(plzArea);
      plz.from = plz.to = '';
      formError.plz.from = formError.plz.to = false;
    };

    const deletePlz = (area) => {
      form.plz.splice(area, 1);
    };

    const startFetching = () => {
      if (errorMessage.value) return;

      repeat.value = true;
      if (form.type === 'mass') form.search = '';
      if (form.type === 'single') form.page = 1;
      if (form.type === 'single') form.limit = 50;
      if (!filterEmployees.value) form.employees.from = form.employees.to = '';
      if (!filterRevenue.value) form.revenue.from = form.revenue.to = '';

      sentForm.value = JSON.parse(JSON.stringify(form));
      toImport.value = [];
      onlyDuplicates.value = false;

      fetchComparison(form);
    };
    const abgleichText = computed(() => ['Start', repeat.value ? 'neue' : undefined, 'Abgleich'].join(' '));

    return {
      form,
      type,
      user,
      startFetching,
      newPlz,
      deletePlz,
      filterEmployees,
      filterRevenue,
      plz,
      abgleichText,
      formError,
      errorMessage,
      teams,
      team,
      selectedSalesRegions,
      salesRegion,
    };
  },
};
</script>
<style>
.vue-slider-dot-tooltip-inner {
  font-size: 25px;
}
</style>
