<template>
  <div class="p-4">
    <div
      class="p-4 container mx-auto text-center w-full text-2xl tracking-wide rounded-lg text-black"
      :class="bgColor.concat(' ', insideClass)"
    >
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: 'bg-white',
    },
    insideClass: {
      type: String,
      default: '',
    },
  },
};
</script>
