<template>
  <div class="flex flex-col items-center" @click="$emit('change-state')">
    <CustomLabel>{{ label }}</CustomLabel>
    <div
      class="flex items-center w-16 h-10 p-1 mx-1 duration-300 ease-in-out bg-gray-300 rounded-full"
      :class="{ 'bg-green-400': currentState }"
    >
      <div
        class="duration-300 ease-in-out transform bg-white rounded-full shadow-md h-7 w-7"
        :class="{ 'translate-x-7': currentState }"
      />
    </div>
  </div>
</template>

<script>
import CustomLabel from './CustomLabel.vue';
export default {
  components: { CustomLabel },
  props: {
    label: {
      type: String,
      required: true,
    },
    currentState: {
      type: Boolean,
    },
  },
  emits: ['change-state'],
};
</script>
