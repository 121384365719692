<template>
  <Card v-if="compare || form.team" v-show="compare">
    <div class="flex justify-around py-1">
      <Dropdown v-model="selectedTeam" label="Team" :options="teams" :error="formError.team" />
      <Dropdown v-model="selectedUser" label="Verkäufer" :options="users" :error="formError.user" />
    </div>
    <div class="py-1">
      <InputText
        v-model="leadtheme"
        label="Leadthema:"
        :label2="form.leadtheme"
        :forceNumber="false"
        placeholder="Erweitere das Leadthema..."
        force="noComparisonOperator"
      />
    </div>
    <div class="flex items-center justify-end pt-4">
      <ButtonOms color="blue" @click="numberOfLeads > 0 ? startImport() : false">
        Importiere {{ numberOfLeads }} Leads
      </ButtonOms>
    </div>
  </Card>
</template>
<script>
import { reactive, ref } from '@vue/reactivity';
import Card from './html/Card.vue';
import InputText from './html/InputText.vue';
import useCadis from '@/composables/useCadis';
import ButtonOms from './html/ButtonOms.vue';
import { watchEffect } from '@vue/runtime-core';
import Dropdown from './html/Dropdown.vue';
export default {
  components: { Card, InputText, ButtonOms, Dropdown },
  setup() {
    const { compare, importLeads, teams, users, toImport, sentForm } = useCadis();
    const form = reactive({
      team: undefined,
      user: undefined,
      leadtheme: undefined,
      toImport: [],
    });
    const leadtheme = ref('');
    const formError = reactive({});
    const numberOfLeads = ref();
    const selectedTeam = ref();
    const selectedUser = ref();

    watchEffect(() => {
      if (typeof selectedUser.value === 'string') form.user = JSON.parse(selectedUser.value);
      if (typeof selectedTeam.value === 'string') form.team = JSON.parse(selectedTeam.value);

      if (compare.value) {
        if (sentForm.value.type === 'single') {
          numberOfLeads.value = toImport.value.length;
        } else {
          numberOfLeads.value = Object.entries(compare.value).filter((x) => x[1].import === true).length;
        }
      }

      const date = new Date().toISOString().slice(0, 10).replace(/-/g, '');
      const team2 = form.team ? form.team.name.replace(/ .*/, '') : undefined;
      form.leadtheme = [date, team2, leadtheme.value].filter(Boolean).join(' ');
    });

    const startImport = () => {
      formError.team = form?.team ? false : true;
      formError.user = form?.user ? false : true;
      if (formError.team || formError.user) return;

      form.toImport = [];
      if (sentForm.value.type === 'single') {
        form.toImport = toImport.value;
      } else {
        Object.values(compare.value).forEach((value) => {
          if (value.import) {
            form.toImport.push(value.uniquekey);
          }
        });
      }
      importLeads(form);
    };
    return {
      form,
      formError,
      selectedTeam,
      selectedUser,
      startImport,
      leadtheme,
      compare,
      teams,
      users,
      numberOfLeads,
      toImport,
      sentForm,
    };
  },
};
</script>
