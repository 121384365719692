<template>
  <div>
    <ImportRunning></ImportRunning>
    <div class="flex justify-center">
      <ComparisonForm />
      <ImportForm />
    </div>
    <TableComparison />
  </div>
</template>
<script>
import TableComparison from '@/components/ComparisonTable.vue';
import ComparisonForm from '@/components/ComparisonForm.vue';
import ImportForm from '@/components/ImportForm.vue';
import ImportRunning from '@/components/ImportRunning.vue';
import useCadis from '@/composables/useCadis';
import useMsal from '@/composables/useMsal';
import router from '@/router';
export default {
  components: {
    TableComparison,
    ComparisonForm,
    ImportForm,
    ImportRunning,
  },
  setup() {
    const { checkUser } = useCadis();
    checkUser();

    const { account } = useMsal();
    if (!account.value) router.push('/');

    return {};
  },
};
</script>
