<template>
  <Card insideClass="max-w-max">
    <ContentLoader :width="340" :height="84" :speed="2" primary-color="#f3f3f3" secondary-color="#ecebeb">
      <rect x="0" y="0" rx="3" ry="3" width="67" height="11" />
      <rect x="76" y="0" rx="3" ry="3" width="140" height="11" />
      <rect x="127" y="48" rx="3" ry="3" width="53" height="11" />
      <rect x="187" y="48" rx="3" ry="3" width="72" height="11" />
      <rect x="18" y="48" rx="3" ry="3" width="100" height="11" />
      <rect x="0" y="71" rx="3" ry="3" width="37" height="11" />
      <rect x="18" y="23" rx="3" ry="3" width="140" height="11" />
      <rect x="166" y="23" rx="3" ry="3" width="173" height="11" />
    </ContentLoader>
  </Card>
</template>
<script>
import Card from './Card.vue';
import { ContentLoader } from 'vue-content-loader';
export default {
  components: { Card, ContentLoader },
};
</script>
