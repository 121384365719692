<template>
  <div class="flex items-center justify-between">
    <div>
      {{ (current - 1) * numberOfItems + 1 }} bis {{ totalPages > current ? current * numberOfItems : total }} von
      {{ total }} Ergebnisse.
    </div>
    <div class="flex items-center">
      <ButtonOms v-if="current > 1" @click="$emit('switchPage', 1)" class="ml-2" color="gray"
        ><ChevronDoubleLeftIcon class="w-5 h-8" />
      </ButtonOms>
      <ButtonOms v-if="current > 1" @click="$emit('switchPage', 'previous')" class="ml-2" color="gray"
        ><ChevronLeftIcon class="w-5 h-8" />
      </ButtonOms>

      <input
        type="text"
        id="pagination"
        class="h-8 px-3 py-6 ml-2 placeholder-white rounded w-44 bg-gray-oms focus:bg-white"
        :placeholder="[current, 'von', totalPages].join(' ')"
        @keyup.enter="
          $emit('switchPage', Number($event.target.value) <= totalPages ? Number($event.target.value) : 'nothing');
          $event.target.value = '';
        "
      />

      <ButtonOms v-if="totalPages > current" @click="$emit('switchPage', 'next')" class="ml-2" color="gray"
        ><ChevronRightIcon class="w-5 h-8"
      /></ButtonOms>
      <ButtonOms
        v-if="totalPages > current"
        @click="
          $emit('switchPage', totalPages);
          setLength;
        "
        class="ml-2"
        color="gray"
        ><ChevronDoubleRightIcon class="w-5 h-8"
      /></ButtonOms>
    </div>
  </div>
</template>

<script>
import { ChevronDoubleRightIcon, ChevronDoubleLeftIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/vue/outline';
import ButtonOms from './ButtonOms.vue';
import { ref, watchEffect } from '@vue/runtime-core';

export default {
  components: {
    ChevronDoubleRightIcon,
    ChevronDoubleLeftIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
    ButtonOms,
  },
  props: {
    current: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    numberOfItems: {
      type: Number,
      default: 50,
    },
  },
  setup(props) {
    const pages = ref([]);
    const totalPages = ref(NaN);

    watchEffect(() => {
      totalPages.value = Math.ceil(props.total / props.numberOfItems);
    });

    // length.value = document.getElementById('pagination').getAttribute('placeholder').length;
    return { pages, totalPages };
  },
};
</script>
