<template>
  <Card v-show="leadthemes.length > 0" insideClass="max-w-max">
    <div class="pr-10 overflow-x-hidden overflow-y-auto max-h-24 whitespace-nowrap">
      <div v-for="leadtheme in leadthemes">
        <a
          :href="
            'https://crmz.org/index.php?module=Leads&page=1&view=List&viewname=4895&search_params=[[[%22cf_751%22%2C%22c%22%2C%22' +
            leadtheme +
            '%22]]]'
          "
          target="_blank"
          class="font-semibold underline hover:text-blue-800"
          >Schaue hier deine neue Leads an: {{ leadtheme }}</a
        >
      </div>
      <div class="text-base">Die Leads werden im Hintergrund importiert.</div>
    </div>
  </Card>
</template>

<script>
import useCadis from '@/composables/useCadis';
import Card from './html/Card.vue';
export default {
  components: { Card },
  setup() {
    const { leadthemes, sentForm } = useCadis();
    const leadtheme = '20201201 Import 78 - Mannheim Test';
    const crmUrl =
      'https://crmz.org/index.php?module=Leads&page=1&view=List&viewname=4895&search_params=[[[%22cf_751%22%2C%22c%22%2C%22' +
      leadtheme +
      '%22]]]';
    return { leadthemes, crmUrl, sentForm };
  },
};
</script>
