<template>
  <div>
    <div class="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto text-black outline-none">
      <div class="relative w-auto max-w-4xl mx-auto my-6">
        <!--content-->
        <div class="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 text-3xl font-semibold border-b">
            <h3 class="">Verknüpfe CRM Eintrag zu CADIS Lead</h3>
            <button
              class="float-right pr-5 uppercase transition-all duration-150 ease-linear bg-transparent"
              type="button"
              @click="$emit('toggleModal')"
            >
              x
            </button>
          </div>
          <!--body-->
          <div class="relative flex-auto p-6">
            <p class="my-4 text-lg leading-relaxed text-gray-500">
              Hiermit wird der ausgewählte CRM-Lead/Unternehmen mit dem betreffenden CADIS Lead verknüpft. Es erfolgt also kein
              Import. Bist du dir wirklich sicher, dass es sich hierbei um das selbe Unternehmen(-zentrale) handelt?
            </p>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t">
            <button
              class="px-6 py-3 mb-1 mr-1 font-bold text-red-500 uppercase transition-all duration-150 ease-linear bg-transparent"
              type="button"
              color="red"
              @click="$emit('toggleModal')"
            >
              Schließen
            </button>
            <ButtonOms
              color="green"
              size="large"
              class="text-xl"
              @click="
                $emit('toggleModal');
                $emit('importUniquekey');
              "
            >
              Verknüpfen
            </ButtonOms>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonOms from './ButtonOms.vue';
export default {
  components: { ButtonOms },
};
</script>
