<template>
  <button class="font-bold text-white rounded focus:outline-none focus:shadow-outline" :class="classes">
    <slot />
  </button>
</template>
<script>
import { computed } from '@vue/runtime-core';
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  setup(props) {
    let colorClasses = {
      red: 'bg-red-oms hover:bg-red-500',
      blue: 'bg-blue-oms hover:bg-blue-500',
      green: 'bg-green-oms hover:bg-green-500',
      gray: 'bg-gray-oms hover:bg-gray-500',
    };
    let sizeClasses = {
      large: 'py-4 px-3',
      medium: 'py-2 px-4 ',
      small: 'text-base  py-1 px-2',
    };

    return {
      classes: computed(() => [colorClasses[props.color], sizeClasses[props.size]].join(' ')),
    };
  },
};
</script>
