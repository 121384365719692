<template>
  <div v-if="loading"><ContentLoader /></div>
  <Card v-if="compare === false" insideClass="max-w-max">Keine Ergebnisse gefunden.</Card>
  <Card v-if="compare" insideClass="max-w-max">
    <div class="flex justify-between">
      <div class="flex">
        <ButtonOms v-if="sentForm.type === 'mass'" @click="selectAll" class="flex mb-4 mr-4" color="green"
          >Alle Selektieren</ButtonOms
        >
        <ButtonOms v-if="sentForm.type === 'mass'" @click="onlyDuplicates = !onlyDuplicates" class="flex mb-4 mr-4" color="gray"
          >Nur Duplikaten</ButtonOms
        >
      </div>
      <!-- <div v-if="sentForm.type === 'mass'">
        Bei eindeutige Duplikaten bitte Verknüpfen statt für nur einmalig auszublenden.
      </div> -->
      <div></div>
    </div>

    <div v-if="sentForm.type === 'single' && total > 0" class="mb-4">
      <Pagination @switchPage="switchPage" :current="sentForm.page" :total="total"></Pagination>
    </div>

    <table class="text-left">
      <tbody
        v-for="singleCompany in compare"
        :class="{
          'opacity-40': singleCompany.import === false && sentForm.type === 'mass',
        }"
        @click="sentForm.type === 'mass' ? (singleCompany.import = !singleCompany.import) : null"
      >
        <tr v-for="(row, cadisvtiger) in onlyDuplicates ? singleCompany.duplicates : singleCompany.matches">
          <td v-for="column in columns" :class="bgColor(column, row)" class="px-3">
            <a
              v-if="column == 'company_name' && cadisvtiger.startsWith('vtiger')"
              :href="crmUrl(row)"
              target="_blank"
              class="font-semibold underline hover:text-blue-800"
              @click="singleCompany.import = !singleCompany.import"
            >
              {{ row[column] }}
            </a>
            <!-- <a
              v-else-if="column == 'company_name' && cadisvtiger === 'cadis' && row['wzw_link']"
              :href="row['wzw_link']"
              target="_blank"
              class="font-semibold underline hover:text-blue-800"
              @click="compare[uniquekey].import = !compare[uniquekey].import"
            >
              {{ row[column] }}
            </a> -->
            <div v-else-if="column == 'revenue'">
              {{
                new Intl.NumberFormat('de-DE', {
                  style: 'currency',
                  currency: 'EUR',
                  maximumFractionDigits: 0,
                }).format(row[column])
              }}
            </div>
            <div v-else>{{ row[column] }}</div>
          </td>
          <div
            v-if="
              sentForm.type === 'single' &&
              cadisvtiger == 'cadis' &&
              !singleCompany.inVtiger &&
              (user.admin ||
                row.operating_unit.match(/(\d+)/)[0].substring(0, 2) == user.branch.match(/(\d+)/)[0].substring(0, 2))
            "
          >
            <ButtonOms
              size="large"
              class="w-40 ml-2 mb-0.5"
              :color="toImport.includes(String(row.uniquekey)) ? 'gray' : 'blue'"
              @click="toggleImport(row.uniquekey)"
              >Import</ButtonOms
            >
          </div>
          <div v-if="cadisvtiger.startsWith('vtiger') && !singleCompany.inVtiger">
            <ButtonOms
              size="large"
              class="w-40 ml-2 my-0.5"
              color="red"
              @click="
                showModal = !showModal;
                formUniquekey.crmid = row['id'];
                formUniquekey.uniquekey = singleCompany.uniquekey;
                formUniquekey.module = row['module'];
              "
              >Verknüpfen</ButtonOms
            >
          </div>
        </tr>
        <hr v-if="(singleCompany.matches && !onlyDuplicates) || (singleCompany.duplicates && onlyDuplicates)" class="h-3" />
      </tbody>
    </table>
    <div v-if="sentForm.type === 'single' && total > 0">
      <Pagination @switchPage="switchPage" :current="sentForm.page" :total="total"></Pagination>
    </div>
  </Card>
  <Modal
    v-if="showModal"
    @toggleModal="showModal = !showModal"
    @importUniquekey="
      importUniquekey(formUniquekey);
      sentForm.type === 'mass'
        ? compare.splice(Object.entries(compare).find(([key, value]) => value['uniquekey'] === formUniquekey.uniquekey)[0], 1)
        : null;
    "
  ></Modal>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import Card from './html/Card.vue';
import ContentLoader from './html/ContentLoader.vue';
import useCadis from '@/composables/useCadis';
import ButtonOms from './html/ButtonOms.vue';
import Pagination from './html/Pagination.vue';
import Modal from './html/Modal.vue';

export default {
  components: { Card, ContentLoader, ButtonOms, Pagination, Modal },
  methods: {
    debug(value) {
      console.log(value);
    },
  },
  setup() {
    const columns = ref(['company_name', 'postalcode', 'street', 'city', 'phonenumber', 'employees', 'revenue']);
    const { user, compare, loading, fetchComparison, total, sentForm, toImport, importUniquekey, onlyDuplicates } = useCadis();
    const formUniquekey = reactive({ crmid: '', uniquekey: '', module: '' });

    const getColor = (value) => {
      if (value >= 0 && value <= 20) {
        return 'bg-green-level';
      } else if (value >= 21 && value <= 40) {
        return 'bg-lime-level';
      } else if (value >= 41 && value <= 60) {
        return 'bg-yellow-level';
      } else if (value >= 61 && value <= 80) {
        return 'bg-orange-level';
      } else if (value >= 81 && value <= 100) {
        return 'bg-red-level';
      }
    };
    const bgColor = (column, row) => {
      if (row.percentage_name === undefined) {
        return 'bg-green-level p-4 text-white';
      }
      if (column == 'company_name') {
        var percentage = row.percentage_name;
      } else if ((column == 'postalcode') | (column == 'street') | (column == 'city')) {
        percentage = row.percentage_address;
      } else if (column == 'phonenumber') {
        percentage = row.percentage_phone;
      } else {
        return 'bg-green-level text-white py-0.5';
      }
      return ['py-0.5', 'text-white', getColor(percentage)].join(' ');
    };
    const crmUrl = (row) => {
      var id = row.id;
      var crmModule = row.module;
      return crmModule == 'account'
        ? 'https://crmz.org/index.php?module=Accounts&view=Detail&record='.concat(id)
        : 'https://crmz.org/index.php?module=Leads&view=Detail&record='.concat(id);
    };

    const toggleAll = ref(false);
    const selectAll = () => {
      Object.entries(compare.value).forEach(([key, value]) => {
        if (value.matches && !onlyDuplicates.value) {
          compare.value[key].import = toggleAll.value;
        } else if (value.duplicates && onlyDuplicates.value) {
          compare.value[key].import = toggleAll.value;
        }
      });
      toggleAll.value = !toggleAll.value;
    };

    const toggleImport = (uniquekey) => {
      var index = toImport.value.indexOf(String(uniquekey));

      if (index === -1) {
        toImport.value.push(String(uniquekey));
      } else {
        toImport.value.splice(index, 1);
      }
    };

    const switchPage = (action) => {
      if (action === 'next') sentForm.value.page = sentForm.value.page + 1;
      if (action === 'previous') sentForm.value.page = sentForm.value.page - 1;
      if (typeof action === 'number') sentForm.value.page = action;
      fetchComparison(sentForm.value);
    };

    const showModal = ref(false);

    return {
      getColor,
      bgColor,
      crmUrl,
      loading,
      columns,
      compare,
      selectAll,
      toggleImport,
      toImport,
      switchPage,
      total,
      sentForm,
      onlyDuplicates,
      formUniquekey,
      importUniquekey,
      showModal,
      user,
    };
  },
};
</script>
